import * as React from 'react';
import './style.scss';
import Text from '../Text';
import Card from './card';
import classNames from 'classnames';

export interface CardBoardData {
  title: string;
  description: string;
  image: string | JSX.Element;
  href?: string;
}

interface Props {
  title: string;
  data: CardBoardData[];
  className?: string;
  isUnderHeader?: boolean;
}

const CardBoard: React.SFC<Props> = ({
  title,
  data,
  className,
  isUnderHeader,
}) => {
  return (
    <div
      className={classNames('card-board', className, {
        'card-board--under-header': isUnderHeader,
      })}
    >
      <Text
        type="h2"
        color="light"
        weight="bold"
        align="center"
        className={'card-board__title h3'}
      >
        {title}
      </Text>
      <div className={'card-board__content'}>
        {data.map((card: CardBoardData, index: number) => {
          return <Card key={`card-${card.title}-${index}`} {...card} />;
        })}
      </div>
    </div>
  );
};

export default CardBoard;
