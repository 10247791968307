import * as React from "react"
import colors from "../../theme/colors"

const GraphQL: React.SFC<{ dark?: boolean }> = ({ dark }) => (
  <svg width={60} height={64} viewBox="0 0 14 15">
    <path
      d="M7 0c.775 0 1.402.598 1.402 1.337 0 .133-.02.261-.058.383l3.235 1.78c.09-.092.197-.173.318-.24.67-.368 1.526-.15 1.916.49.385.64.156 1.456-.514 1.827-.12.066-.245.113-.373.142v3.563c.126.03.25.076.369.14.674.372.903 1.188.514 1.828-.386.64-1.245.858-1.916.49a1.382 1.382 0 01-.347-.268l-3.215 1.77c.046.132.07.274.07.421C8.402 14.4 7.776 15 7 15s-1.402-.598-1.402-1.337c0-.131.02-.258.057-.379l-3.233-1.78c-.09.09-.196.17-.315.236-.675.368-1.53.15-1.916-.49-.385-.64-.156-1.456.514-1.827.119-.065.243-.112.37-.141V5.719A1.446 1.446 0 01.7 5.577C.031 5.21-.199 4.39.187 3.75c.386-.64 1.245-.858 1.916-.49.12.066.226.147.317.238l3.236-1.78a1.283 1.283 0 01-.058-.381C5.598.597 6.225 0 7 0zm4.232 10.902H2.767l-.014.05 3.238 1.783A1.431 1.431 0 017 12.326c.41 0 .78.168 1.036.436l3.222-1.773a1.3 1.3 0 01-.026-.087zM7 2.674a1.47 1.47 0 01-.391-.053L2.375 9.615a1.305 1.305 0 01.394.65h8.462a1.305 1.305 0 01.393-.651L7.392 2.62A1.47 1.47 0 017 2.674zm-1.008-.408L2.75 4.051c.101.336.064.71-.133 1.036a1.39 1.39 0 01-.875.629v3.568l.055.014 4.234-6.994a1.378 1.378 0 01-.04-.038zm1.979.036l4.233 6.995a1.47 1.47 0 01.054-.014V5.716a1.39 1.39 0 01-.875-.63 1.28 1.28 0 01-.134-1.033L8.006 2.268a1.377 1.377 0 01-.035.034z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default GraphQL
