import * as React from "react"
import colors from "../../theme/colors"

const Firebase: React.SFC<{ dark?: boolean }> = ({ dark }) => (
  <svg width={51.2} height={64} viewBox="0 0 12 15">
    <path
      d="M0 12.103l6.345-5.756L4.831 3.74l.837-1.448a.372.372 0 01.25-.174L6 2.11c.14 0 .267.07.332.182l1.53 2.65L9.81 3.169c.1-.09.245-.122.379-.084a.353.353 0 01.256.268L12 12.103 6.525 14.87c-.34.173-.756.173-1.097 0L0 12.103zm0 0L2.03.29a.359.359 0 01.307-.284.387.387 0 01.394.175l2.098 3.56L0 12.103z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default Firebase
