import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { mapObjIndexed } from 'ramda';

interface ServicesImages {
  mobile: IGatsbyImageData;
  web: IGatsbyImageData;
  startup: IGatsbyImageData;
  digital: IGatsbyImageData;
  agile: IGatsbyImageData;
  devTeam: IGatsbyImageData;
}

const useServices = () => {
  getImage;
  const data = useStaticQuery(graphql`
    {
      mobile: file(relativePath: { eq: "mobile_development.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      web: file(relativePath: { eq: "web-service.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      startup: file(relativePath: { eq: "startup-service.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      digital: file(relativePath: { eq: "graph-service.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      agile: file(relativePath: { eq: "agile.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      devTeam: file(relativePath: { eq: "dev-team.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);
  return mapObjIndexed(getImage, data);
};

export default useServices;
