import * as React from 'react';
import { CardBoardData } from '.';
import { Link } from 'gatsby-plugin-react-i18next';
import Text from '../Text';
import './style.scss';
import { useCallback } from 'react';

type Props = CardBoardData;

const Card: React.SFC<Props> = props => {
  const { title, description, image, href } = props;

  const renderContent = useCallback(
    () => (
      <>
        <div className={'card-board__content__card__content'}>
          <Text
            type="h3"
            color="dark"
            weight="medium"
            className={'card-board__content__card__content__title h4'}
          >
            {title}
          </Text>
          <Text
            type="p"
            color="dark"
            weight="regular"
            className={'card-board__content__card__content__description'}
          >
            {description}
          </Text>
        </div>
        {typeof image === 'string' ? (
          <img
            src={image}
            className="card-board__content__card__img"
            alt={title}
          />
        ) : (
          <div className="card-board__content__card__svg">{image}</div>
        )}
      </>
    ),
    [image, description, title]
  );

  return href ? (
    <Link to={href} className={'card-board__content__card'}>
      {renderContent()}
    </Link>
  ) : (
    <div className={'card-board__content__card'}>{renderContent()}</div>
  );
};

export default Card;
