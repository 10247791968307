import * as React from 'react';

function Ownership(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={164} height={164} viewBox="0 0 164 164" fill="none" {...props}>
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={164}
        height={164}
        style={{
          maskType: 'alpha',
        }}
      >
        <rect width={164} height={164} fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.0874 93.1092C40.596 92.9339 41.1537 92.9708 41.6348 93.2117L84.6426 114.748C86.2424 115.549 86.0658 117.887 84.3637 118.439L58.977 126.667C58.4772 126.829 57.9337 126.788 57.4641 126.553L15.1037 105.317C13.5206 104.524 13.6739 102.216 15.3481 101.639L40.0874 93.1092ZM21.1447 103.871L58.5237 122.609L78.4867 116.139L40.5959 97.165L21.1447 103.871Z"
          fill="#5E626E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.9076 92.5094L61.1998 83.6509C62.2121 83.209 63.391 83.6714 63.833 84.6837C64.2749 85.696 63.8125 86.8749 62.8002 87.3168L41.5394 96.5981C40.9335 96.8626 40.2359 96.8106 39.676 96.4591L14.9367 80.9298C13.5289 80.0461 13.7508 77.9305 15.3113 77.3581L48.6717 65.1228C49.7087 64.7424 50.8577 65.2748 51.238 66.3118C51.6184 67.3488 51.086 68.4978 50.049 68.8782L20.5166 79.7096L40.9076 92.5094Z"
          fill="#5E626E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.093 92.5094L104.8 83.6509C103.788 83.209 102.609 83.6714 102.167 84.6837C101.725 85.696 102.188 86.8749 103.2 87.3168L124.461 96.5981C125.067 96.8626 125.764 96.8106 126.324 96.4591L151.064 80.9298C152.471 80.0461 152.249 77.9305 150.689 77.3581L117.329 65.1228C116.291 64.7424 115.142 65.2748 114.762 66.3118C114.382 67.3488 114.914 68.4978 115.951 68.8782L145.484 79.7096L125.093 92.5094Z"
          fill="#5E626E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.407 93.1092C126.898 92.9339 126.34 92.9708 125.859 93.2117L82.8516 114.748C81.2517 115.549 81.4284 117.887 83.1304 118.439L108.517 126.667C109.017 126.829 109.56 126.788 110.03 126.553L152.39 105.317C153.974 104.524 153.82 102.216 152.146 101.639L127.407 93.1092ZM146.349 103.871L108.971 122.609L89.0075 116.139L126.898 97.165L146.349 103.871Z"
          fill="#5E626E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42 135.764V129C42 127.895 41.1046 127 40 127C38.8954 127 38 127.895 38 129V137C38 137.758 38.428 138.45 39.1056 138.789L83.1056 160.789C84.4354 161.454 86 160.487 86 159V129C86 127.895 85.1046 127 84 127C82.8954 127 82 127.895 82 129V155.764L42 135.764Z"
          fill="#5E626E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126 135.764V129C126 127.895 126.895 127 128 127C129.105 127 130 127.895 130 129V137C130 137.758 129.572 138.45 128.894 138.789L84.8944 160.789C83.5646 161.454 82 160.487 82 159V129C82 127.895 82.8954 127 84 127C85.1046 127 86 127.895 86 129V155.764L126 135.764Z"
          fill="#5E626E"
        />
        <rect x={77} y={86} width={5} height={21} rx={2.5} fill="#5E626E" />
        <rect x={68} y={78} width={5} height={21} rx={2.5} fill="#5E626E" />
        <rect x={95} y={78} width={5} height={21} rx={2.5} fill="#5E626E" />
        <rect x={86} y={86} width={5} height={21} rx={2.5} fill="#5E626E" />
        <path
          d="M73.4164 71.0668L58 76V57V57L67.7053 45.6772"
          stroke="#00BB8E"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.6445 71.406L109 75.9998V56.9998V56.9998L100.079 46.5918"
          stroke="#00BB8E"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84 80C87.8815 80 91.4401 76.8039 94.2106 71.4876C97.7475 64.7008 100 54.4587 100 43C100 22.5655 92.8366 6 84 6C75.1634 6 68 22.5655 68 43C68 54.4521 70.2499 64.6889 73.7833 71.4758C76.5547 76.7992 80.1157 80 84 80Z"
          stroke="#00BB8E"
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84 41C87.866 41 91 37.866 91 34C91 30.134 87.866 27 84 27C80.134 27 77 30.134 77 34C77 37.866 80.134 41 84 41Z"
          fill="#00BB8E"
        />
      </g>
    </svg>
  );
}

const MemoOwnership = React.memo(Ownership);
export default MemoOwnership;
