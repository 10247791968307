import * as React from "react"
import colors from "../../theme/colors"

const Kotline = ({ dark = false }) => (
  <svg width={64} height={64} viewBox="0 0 15 15">
    <path
      d="M7.525 7.475L15 15H0l7.525-7.525zM13.75 0L0 13.75V9.175L8.775 0h4.975zM7.525 0L0 8.125V0h7.525z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default Kotline
