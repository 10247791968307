import * as React from "react"
import colors from "../../theme/colors"

const Swift: React.SFC<{ dark?: boolean }> = ({ dark = false }) => (
  <svg width={64} height={64} viewBox="0 0 15 15">
    <path
      d="M10.577 0l.268.001c.151 0 .302.003.453.007.329.009.66.028.985.087.33.059.637.156.937.308a3.147 3.147 0 011.377 1.377c.153.3.25.607.309.937.058.325.077.656.086.985.004.151.006.302.007.453l.001.537v5.616l-.001.537c-.001.151-.003.302-.007.453-.009.328-.028.66-.086.985-.06.33-.157.637-.31.937a3.144 3.144 0 01-1.376 1.377c-.3.152-.607.25-.937.308a6.56 6.56 0 01-.985.087 21.65 21.65 0 01-.453.007l-.537.001H4.692l-.537-.001c-.151-.001-.302-.003-.453-.007a6.555 6.555 0 01-.985-.087 3.324 3.324 0 01-.937-.308A3.146 3.146 0 01.403 13.22c-.153-.3-.25-.607-.309-.937a6.572 6.572 0 01-.086-.985c-.004-.151-.006-.302-.007-.453L0 10.308V4.423l.001-.268c.001-.151.003-.302.007-.453.009-.329.028-.66.086-.985.06-.33.156-.637.31-.937A3.147 3.147 0 012.006.3c.228-.094.462-.16.71-.204.243-.044.491-.066.738-.078l.247-.01c.15-.003.302-.005.453-.006L4.692 0h5.885zM8.465 2.13c1.127 1.528 1.625 3.378 1.182 4.996a4.2 4.2 0 01-.14.418 3.44 3.44 0 00-.224-.133s-2.558-1.58-5.33-4.373c-.073-.073 1.478 2.217 3.238 4.076-.829-.465-3.14-2.146-4.603-3.485.18.3.394.588.629.866 1.221 1.55 2.815 3.461 4.724 4.93-1.342.82-3.237.884-5.124 0a7.74 7.74 0 01-1.311-.783 7.856 7.856 0 003.526 3.024c1.785.767 3.56.715 4.883.012v.002l.02-.012c.054-.03.107-.059.16-.09.635-.33 1.89-.665 2.564.646.165.32.516-1.379-.773-2.965l-.001-.001c.016-.057.033-.113.048-.17.623-2.48-.897-5.413-3.468-6.957z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default Swift
