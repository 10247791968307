import * as React from "react"
import colors from "../../theme/colors"

const TypeScript = ({ dark = false }) => (
  <svg width={64} height={64} viewBox="0 0 15 15">
    <path
      d="M0 7.5V15h15V0H0v7.5zm12.088-.598c.381.096.672.265.938.54.138.148.343.418.36.482.004.019-.648.457-1.043.702-.014.01-.072-.052-.136-.147-.193-.281-.395-.403-.705-.424-.454-.031-.747.207-.745.605a.55.55 0 00.064.28c.1.208.286.331.87.584 1.073.462 1.533.766 1.819 1.2.319.483.39 1.255.173 1.828-.238.624-.828 1.048-1.66 1.188-.256.046-.866.039-1.142-.011-.602-.108-1.174-.405-1.526-.796-.138-.152-.407-.55-.39-.578a1.12 1.12 0 01.137-.088l.558-.322.43-.25.091.134c.126.192.402.457.569.545.479.252 1.136.216 1.46-.074a.552.552 0 00.195-.45.55.55 0 00-.112-.381c-.117-.167-.355-.307-1.031-.6-.774-.333-1.107-.54-1.412-.869a1.978 1.978 0 01-.412-.75c-.057-.212-.071-.743-.026-.957.16-.748.724-1.27 1.538-1.424.264-.05.879-.03 1.138.033zm-3.521.627l.004.614H6.62V13.69h-1.38V8.143H3.285V7.54c0-.333.007-.611.016-.619.008-.01 1.196-.014 2.636-.011l2.622.007.007.612z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default TypeScript
