import * as React from "react"
import colors from "../../theme/colors"

const NodeJs = ({ dark = false }) => (
  <svg width={60} height={64} viewBox="0 0 14 15">
    <path
      d="M7.612.148l5.781 3.176c.374.205.607.589.607 1.001v6.344c0 .412-.233.796-.607 1.002l-5.781 3.175a1.265 1.265 0 01-.61.154c-.21.003-.42-.05-.606-.151l-1.93-1.087c-.289-.153-.148-.207-.053-.239.384-.128.462-.157.873-.378.043-.023.1-.015.144.01l1.483.836c.053.028.13.028.18 0l5.782-3.173a.172.172 0 00.089-.149V4.325a.175.175 0 00-.09-.15l-5.78-3.171a.186.186 0 00-.179 0l-5.78 3.17a.174.174 0 00-.091.15v6.344c0 .06.034.118.09.147l1.583.87c.86.409 1.386-.073 1.386-.557V4.866c0-.09.073-.158.167-.158h.733c.091 0 .166.069.166.158v6.264c0 1.09-.625 1.716-1.712 1.716-.334 0-.598 0-1.333-.344l-1.517-.83A1.153 1.153 0 010 10.667V4.324c0-.413.232-.798.607-1.002L6.396.148a1.326 1.326 0 011.216 0zm.941 4.395c1.852 0 2.77.611 2.886 1.924a.155.155 0 01-.045.122.176.176 0 01-.123.051h-.752a.164.164 0 01-.162-.124c-.18-.76-.618-1.004-1.806-1.004-1.33 0-1.486.44-1.486.77 0 .401.183.518 1.978.744 1.777.224 2.621.54 2.621 1.728 0 1.197-1.05 1.884-2.883 1.884l.008-.007c-2.53 0-3.06-1.104-3.06-2.03 0-.088.074-.158.167-.158h.747c.082 0 .152.057.165.134.113.724.448 1.089 1.979 1.089 1.218 0 1.737-.262 1.737-.876 0-.354-.148-.617-2.041-.793-1.584-.148-2.563-.48-2.563-1.684 0-1.11.985-1.77 2.633-1.77z"
      fill={
        dark
          ? colors.additional.greyColor.TUNDORA
          : colors.main.backgroundColor.PRIMARY
      }
      fillRule="nonzero"
    />
  </svg>
)

export default NodeJs
